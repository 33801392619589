import { useArticleForm } from '@/routes/articles/-contexts';
import { SEMI_BASEMENT_FLOOR } from '@daangn/realty-sdk';
import { useController } from 'react-hook-form';
import FieldLogger from '@/routes/articles/-components/FieldLogger';
import { Checkbox, TextField } from '@daangn/carotene';

const AreaInfoField = () => {
  const { control } = useArticleForm();

  const roomCnt = useController({ control, name: 'roomCnt' });
  const bathroomCnt = useController({ control, name: 'bathroomCnt' });
  const floor = useController({ control, name: 'floor' });
  const topFloor = useController({ control, name: 'topFloor' });

  const handleSemiBasementChange = (isChecked: boolean) => {
    if (isChecked) {
      floor.field.onChange(SEMI_BASEMENT_FLOOR);
    } else {
      floor.field.onChange('');
    }
  };

  return (
    <>
      <div className="flex gap-6 *:flex-grow">
        <FieldLogger name="room">
          <TextField
            {...roomCnt.field}
            value={String(roomCnt.field.value ?? '')}
            onChange={(e) => roomCnt.field.onChange(Number(e.target.value))}
            placeholder="0"
            type="number"
            label="방 개수"
            invalid={!!roomCnt.fieldState.error}
            errorMessage={roomCnt.fieldState.error?.message}
          />
        </FieldLogger>
        <FieldLogger name="bathroom">
          <TextField
            {...bathroomCnt.field}
            value={String(bathroomCnt.field.value ?? '')}
            onChange={(v) => bathroomCnt.field.onChange(Number(v))}
            placeholder="0"
            type="number"
            invalid={!!bathroomCnt.fieldState.error}
            label="욕실 개수"
            errorMessage={bathroomCnt.fieldState.error?.message}
          />
        </FieldLogger>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex gap-6 *:flex-grow">
          <FieldLogger name="top_floor">
            <TextField
              {...topFloor.field}
              value={String(topFloor.field.value ?? '')}
              placeholder="0"
              type="number"
              label="건물 전체 층"
              invalid={!!topFloor.fieldState.error}
              errorMessage={topFloor.fieldState.error?.message}
            />
          </FieldLogger>
          <FieldLogger name="floor">
            <TextField
              {...floor.field}
              value={
                floor.field.value === SEMI_BASEMENT_FLOOR ? '' : String(floor.field.value ?? '')
              }
              disabled={floor.field.value === SEMI_BASEMENT_FLOOR}
              placeholder="0"
              type="number"
              label="해당 층"
              invalid={!!floor.fieldState.error}
              errorMessage={floor.fieldState.error?.message}
            />
          </FieldLogger>
        </div>
        <FieldLogger name="semi_basement">
          <Checkbox
            checked={floor.field.value === SEMI_BASEMENT_FLOOR}
            onCheckedChange={handleSemiBasementChange}
            label="반지하"
          />
        </FieldLogger>
      </div>
    </>
  );
};

export default AreaInfoField;
