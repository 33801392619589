import { createFileRoute, redirect, useRouter } from '@tanstack/react-router';
import { QRCode } from 'react-qrcode-logo';
import { useForm, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ArticleFormType, articleFormScheme } from '@/scheme/article';
import SalesTypeField from '@/routes/articles/-components/SalesTypeField';
import BuildingUsageField from '@/routes/articles/-components/BuildingUsageField';
import AddressInfoField from '@/routes/articles/-components/AddressInfoField';
import PriceField from '@/routes/articles/-components/PriceField';
import OrientationField from '@/routes/articles/-components/OrientationField';
import AreaField from '@/routes/articles/-components/AreaField';
import RoomFloorField from '@/routes/articles/-components/RoomFloorField';
import ManageCostField from '@/routes/articles/-components/ManageCostField';
import RequiredOptionField from '@/routes/articles/-components/RequiredOptionField';
import OptionField from '@/routes/articles/-components/OptionField';
import QualitativeField from '@/routes/articles/-components/QualitativeField';
import ContentField from '@/routes/articles/-components/ContentField';
import AddressField from '@/routes/articles/-components/AddressField';
import ImageField from '@/routes/articles/-components/ImageField';
import VideoField from '@/routes/articles/-components/VideoField';
import MoveInDateField from '@/routes/articles/-components/MoveInDateField';
import { lazy, Suspense, useEffect, useRef, useState } from 'react';
import Section from '@/routes/articles/-components/Section';
import TradeTypeField from '@/routes/articles/-components/TradeTypeField';
import IncludeManageCostOptionField from '@/routes/articles/-components/IncludeManageCostOptionField';
import Divider from '@/routes/articles/-components/Divider';
import BuildingApprovalDate from '@/routes/articles/-components/BuildingApprovalDateField';
import { fetchQuery, graphql, useMutation } from 'react-relay';
import { newPageMutation } from '@/__generated__/newPageMutation.graphql';
import { Spinner } from '@daangn/sprout-components-spinner';
import { z } from 'zod';
import { getRelayNetworkErrorHandler, handleMutationResponse } from '@/relay/utils';
import { ActionHistoryKey, realtyLocalStorage } from '@/utils/localStorage';
import PageError from '@/components/PageError';
import logger from '@/utils/Logger';
import { captureException, ErrorBoundary } from '@sentry/react';
import ReportBanner from '@/routes/articles/-components/ReportBanner';
import AiConversionDialog from '@/routes/articles/-components/AiConversionDialog';
import RealtyHomeBanner from '@/routes/articles/-components/RealtyHomeBanner';
import BrokerFaqBanner from '@/routes/articles/-components/BrokerFaqBanner';
import ContactTargetNumberField from '@/routes/articles/-components/ContactTargetNumberField';
import RequestVerifyDialog from '@/routes/articles/-components/RequestVerifyDialog';
import { createMinikarrotScheme } from '@daangn/webview-link-router';
import { config, STAGE } from '@/config';
import BrokerInfo from '@/routes/articles/-components/BrokerInfo';
import useAppQuery from '@/hooks/useAppQuery';
import MainEnvironment from '@/relay/environment';
import type { mainQuery } from '@/__generated__/mainQuery.graphql';
import { Button } from '@daangn/carotene';

export const Route = createFileRoute('/articles/new')({
  beforeLoad: async ({ search, context }) => {
    const result = await context.appQuery.observable.toPromise();

    if (!result?.viewer) {
      throw redirect({
        to: '/auth/login',
        search,
      });
    }

    if (!result.viewer.bizProfileBroker) {
      const permissionError = new Error('Not verified business profile');
      permissionError.name = 'BusinessProfileError';
      throw permissionError;
    }
  },
  component: Page,
  onError: (error) => {
    captureException(error);
  },
  errorComponent: (props) => {
    const error = props.error as Error;

    if (error.name === 'BusinessProfileError') {
      return <BusinessProfileErrorComponent />;
    }

    return <PageError />;
  },
});

function Page() {
  const containerRef = useRef<HTMLFormElement>(null);
  const form = useForm<ArticleFormType>({
    resolver: zodResolver(articleFormScheme),
  });
  const { viewer } = useAppQuery();
  const [createdArticleId, setCreatedArticleId] = useState<string | null>(null);
  const [isAIConversionComplete, setIsAIConversionComplete] = useState(false);
  const [showRequestVerifyDialog, setShowRequestVerifyDialog] = useState(false);
  const [showRealtyHomeBanner, setShowRealtyHomeBanner] = useState(
    () => !realtyLocalStorage.getActionHistory(ActionHistoryKey.SeenRealtyHomeBanner)
  );

  const [createArticle, isInFlight] = useMutation<newPageMutation>(graphql`
    mutation newPageMutation($input: CreateArticleInputV3!) {
      createArticleV3(input: $input) {
        __typename
        ... on CreateArticleOutput_Result {
          result {
            article {
              id
              originalId
            }
          }
        }
        ... on ToastError {
          toastError {
            message
          }
        }
      }
    }
  `);

  const handleClickSubmit = async () => {
    logger.click({
      name: 'click_submit',
    });

    form.handleSubmit(
      async (values: unknown) => {
        const articleInput = values as z.infer<typeof articleFormScheme>;
        const {
          trades,
          images,
          videos,
          requiredOptions,
          options,
          isContactTargetEnabled,
          ...input
        } = articleInput;

        createArticle({
          variables: {
            input: {
              ...input,
              trades,
              imageIds: images?.map((image) => image.id) ?? [],
              originalVideoIds: videos?.map((video) => video.id) ?? [],
              originalBigStreamIds: videos?.map((video) => video.bigStreamId) ?? [],
              writerType: 'BROKER',
              options: [...requiredOptions, ...(options || [])],
              contactTargetPhoneNumber: isContactTargetEnabled
                ? viewer?.bizProfileBroker?.contactPhoneNumber
                : undefined,
            },
          },
          onCompleted: (data) => {
            handleMutationResponse(data.createArticleV3, {
              onResult: ({ result }) => {
                realtyLocalStorage.clearTempArticle();
                realtyLocalStorage.setActionHistory(ActionHistoryKey.SeenRealtyHomeBanner);

                setCreatedArticleId(result.article.originalId);

                logger.click({
                  name: 'complete_submit',
                  params: {
                    input,
                  },
                });
              },
              onToastError(toastError) {
                window.alert(toastError.toastError.message);
              },
            });
          },
          onError: getRelayNetworkErrorHandler(),
        });
      },
      (errors) => {
        logger.click({
          name: 'failed_submit',
          params: { errors },
        });

        const firstError = Object.entries(errors).sort((a, b) => {
          const aTop = containerRef.current?.querySelector<HTMLElement>(
            `[name=${a[0]}]`
          )?.offsetTop;
          const bTop = containerRef.current?.querySelector<HTMLElement>(
            `[name=${b[0]}]`
          )?.offsetTop;

          if (aTop && bTop) {
            return aTop - bTop;
          }
          return 0;
        })[0];

        if (firstError) {
          const [name] = firstError;
          const element = containerRef.current?.querySelector<HTMLElement>(`[name=${name}]`);
          element?.focus();
        }
      }
    )();
  };

  const handleClickWriteMore = () => {
    logger.click({
      name: 'click_write_more',
    });

    form.reset();
    setShowRealtyHomeBanner(false);
    setCreatedArticleId(null);
    setIsAIConversionComplete(false);
  };

  const handleClickRequestVerify = () => {
    logger.click({
      name: 'click_request_verify',
    });

    setShowRequestVerifyDialog(true);
  };

  const handleAIConversionComplete = () => {
    setIsAIConversionComplete(true);
  };

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = '';
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    form.watch((values) => {
      realtyLocalStorage.setTempArticle(values);
    });
  }, []);

  useEffect(() => {
    const tempArticle = realtyLocalStorage.getTempArticle();

    if (tempArticle) {
      const confirm = window.confirm('작성 중인 글이 있어요. 글을 이어서 쓸까요?');

      if (confirm) {
        logger.click({
          name: 'confirm_continue_writing',
        });
        form.reset(tempArticle);
      } else {
        realtyLocalStorage.clearTempArticle();
      }
    }
  }, []);

  if (createdArticleId) {
    return (
      <div className="flex flex-col gap-6 pt-5 md:pt-[200px] justify-center items-center text-center">
        <img
          style={{ width: 116, height: 120 }}
          src="https://asset-town.krrt.io/production/illust/ccea2e75-8732-4647-b41d-28d2bd5666b1/10b01f333feecd723b9bca674cd544b5e96b8bd4.webp"
        />
        <div className="flex flex-col gap-2">
          <h1 className="heading-large text-2xl">게시글 작성이 완료되었어요</h1>
          <p className="body-medium-default">
            내 게시글은 당근 앱 {'>'} 나의 당근 {'>'} 부동산에서 확인하실 수 있어요.
          </p>
        </div>
        <div className="space-y-3">
          <div>
            <Button size="xlarge" onClick={handleClickWriteMore} style={{ minWidth: 165 }}>
              매물 추가 등록하기
            </Button>
          </div>
          <div>
            <Button
              type="button"
              size="xlarge"
              variant="neutral"
              onClick={handleClickRequestVerify}
              style={{ minWidth: 165 }}
            >
              집주인 인증 요청
            </Button>
          </div>
        </div>
        <RequestVerifyDialog
          isOpen={showRequestVerifyDialog}
          setOpen={setShowRequestVerifyDialog}
          articleId={createdArticleId}
        />
      </div>
    );
  }
  return (
    <div className="relative pt-12 pb-36">
      {isInFlight && (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-full flex items-center justify-center bg-bg-overlay-default z-50">
          <Spinner />
        </div>
      )}
      <FormProvider {...form}>
        <form ref={containerRef} className="flex flex-col gap-10" onSubmit={handleClickSubmit}>
          <ErrorBoundary>
            <Suspense>
              <BrokerFaqBanner />
            </Suspense>
          </ErrorBoundary>
          <div className="flex flex-col gap-12">
            <Section title="중개사 정보">
              <BrokerInfo />
            </Section>
            <Divider />
            <Section
              title="기본 정보"
              action={
                !isAIConversionComplete ? (
                  <AiConversionDialog onConversionComplete={handleAIConversionComplete} />
                ) : null
              }
            >
              <SalesTypeField />
              <TradeTypeField />
            </Section>
            <Divider />
            <Section title="매물 소재지/면적">
              <div className="grid grid-cols-2 gap-6">
                <AddressField />
              </div>
              <AreaField />
            </Section>
            <Divider />
            <Section title="매물 정보">
              <BuildingApprovalDate />
              <RoomFloorField />
              <OrientationField />
              <BuildingUsageField />
              <div className="grid grid-cols-2 gap-6">
                <RequiredOptionField />
              </div>
            </Section>
            <Divider />
            <PriceField />
            <Divider />
            <Section title="관리비">
              <ManageCostField />
              <div className="grid grid-cols-2 gap-6">
                <IncludeManageCostOptionField />
              </div>
            </Section>
            <Divider />
            <Section title="내외부 시설">
              <OptionField />
            </Section>
            <Divider />
            <Section title="매물 상세 정보">
              <div className="flex gap-6 justify-between">
                <MoveInDateField />
                <AddressInfoField />
              </div>
              <QualitativeField />
              <ContentField />
              <ImageField />
              <VideoField />
              <Divider />
              <ContactTargetNumberField />
            </Section>
          </div>
        </form>
        <div className="mt-24 flex flex-col gap-7">
          {showRealtyHomeBanner && <RealtyHomeBanner />}
          <ReportBanner />
        </div>
        <Suspense fallback={null}>
          <DevFormHelper />
        </Suspense>
      </FormProvider>

      <div className="fixed bottom-0 left-0 right-0 border-t border-bg-neutralHover bg-bg-layerDefault">
        <div className="flex items-center h-24 px-2 md:px-0 py-6 max-w-screen-md mx-auto">
          <p className="body-xsmall-default text-fg-placeholder">
            매물 등록 시에 공인중개사법 및 하위 법령, 중개대상물의 표시·광고 명시사항 세부기준 등을
            준수해야 하며,
            <br />
            이를 준수하지 않아 발생하는 불이익에 대해 당사는 책임지지 않습니다.
          </p>
          <div className="spacer" />
          <Button type="submit" size="xlarge" onClick={handleClickSubmit} disabled={isInFlight}>
            매물 등록
          </Button>
        </div>
      </div>
    </div>
  );
}

const DevFormHelper =
  STAGE === 'production'
    ? () => null
    : lazy(() => import('@/routes/articles/-components/DevFormHelper'));

function BusinessProfileErrorComponent() {
  const appScheme = createMinikarrotScheme({
    url: `${config.webviewUrl}/biz_profile/create`,
    stage: STAGE,
  });
  const router = useRouter();

  useEffect(() => {
    logger.impression({
      name: 'show_biz_profile_error',
    });

    const interval = setInterval(() => {
      fetchQuery<mainQuery>(MainEnvironment, router.options.context.appQuery.node, {})
        .toPromise()
        .then((data) => {
          if (data?.viewer?.bizProfileBroker) {
            window.location.reload();
          }
        })
        .catch(() => {});
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="h-full flex items-center justify-center text-center">
      <div className="space-y-8">
        <div className="space-y-2">
          <h1 className="heading-xlarge">비즈프로필 가입이 필요해요</h1>
          <h3 className="body-large-default">아래의 QR 코드를 통해 바로 가입할 수 있어요</h3>
        </div>
        <div className="p-[60px] bg-bg-layerDefaultHover">
          <QRCode
            value={appScheme}
            size={248}
            logoImage="https://assetstorage.krrt.io/1138369647032855545/ba118f7e-06c5-4d72-b10d-9a64641e7ae5/width=65.7,height=112.3.webp"
            logoWidth={24}
            logoHeight={40}
          />
        </div>
      </div>
    </div>
  );
}
