import {
  useIO
} from "./chunk-H4LUPZRE.mjs";

// src/components/InViewChecker.tsx
import { useRef } from "react";
import { jsx } from "react/jsx-runtime";
var InViewChecker = ({ children, options, onInView, ...props }) => {
  const container = useRef(null);
  useIO(container, (e) => e.isIntersecting && onInView(e), options);
  return /* @__PURE__ */ jsx("div", { ref: container, ...props, children });
};

// src/components/InfiniteLoader.tsx
import { useRef as useRef2 } from "react";
import { jsx as jsx2 } from "react/jsx-runtime";
var InfiniteLoader = ({ hasNext, onLoadMore, children }) => {
  const loadingRef = useRef2(null);
  useIO(loadingRef, (e) => e.isIntersecting && onLoadMore());
  if (!hasNext) {
    return null;
  }
  return /* @__PURE__ */ jsx2("div", { ref: loadingRef, children });
};
export {
  InViewChecker,
  InfiniteLoader
};
