import axios from 'axios';
import { BuildingLedgerTitle } from '@daangn/realty-sdk/services';
const api = axios.create({
  baseURL: 'https://realty-traded.karrot.workers.dev',
});

export default {
  getBuildingLedgerTitle: async (address: string) => {
    const { data } = await api.get<BuildingLedgerTitle[]>('/building-ledger/title', {
      params: { address },
    });

    return data;
  },
};
