/**
 * @generated SignedSource<<9177094fb381c4859c6fc128b3050681>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type AddressKind = "BUILDING" | "GROUP_BUILDING" | "LAND";
import { FragmentRefs } from "relay-runtime";
export type useSearchCorAddressResult$data = {
  readonly searchCorAddress: {
    readonly edges: ReadonlyArray<{
      readonly cursor: string;
      readonly node: {
        readonly address: string;
        readonly corRealtyId: string;
        readonly isRentalHouse: boolean;
        readonly kind: AddressKind;
      };
    }>;
  };
  readonly " $fragmentType": "useSearchCorAddressResult";
};
export type useSearchCorAddressResult$key = {
  readonly " $data"?: useSearchCorAddressResult$data;
  readonly " $fragmentSpreads": FragmentRefs<"useSearchCorAddressResult">;
};

import useSearchCorAddressResultRefetchQuery_graphql from './useSearchCorAddressResultRefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "searchCorAddress"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": 9,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "input"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": useSearchCorAddressResultRefetchQuery_graphql
    }
  },
  "name": "useSearchCorAddressResult",
  "selections": [
    {
      "alias": "searchCorAddress",
      "args": [
        {
          "kind": "Variable",
          "name": "data",
          "variableName": "input"
        }
      ],
      "concreteType": "AddressCorConnection",
      "kind": "LinkedField",
      "name": "__useSearchCorAddressResult_searchCorAddress_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AddressCorEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CorAddress",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "address",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "corRealtyId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "kind",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isRentalHouse",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "cda8b5b1d7ecabac9bd479356e092761";

export default node;
