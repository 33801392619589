import { useSearchCorAddressResult$data } from '__generated__/useSearchCorAddressResult.graphql';
import { ArticleFormType } from '@/scheme/article';
import { useState } from 'react';
import { CoordinateFinder, parseAddress } from '@daangn/realty-sdk';
import { config } from '@/config';

export type CorFormState = Required<
  Pick<ArticleFormType, 'address' | 'fullAddress' | 'coordinate' | 'corRealtyId'>
>;

type Params = {
  onSubmit: (corFormState: CorFormState) => void;
};

const coordinateFinder = new CoordinateFinder({
  kakaoMapKey: config.kakaoRestKey,
  googleMapKey: config.googleMapKey,
});

const useCheckWritableAddress = ({ onSubmit }: Params) => {
  const [isChecking, setIsChecking] = useState(false);
  const handleSubmit = (item: CorFormState) => {
    onSubmit(item);
  };

  const handleItemClick = async (
    node: useSearchCorAddressResult$data['searchCorAddress']['edges'][number]['node']
  ) => {
    setIsChecking(true);
    try {
      if (node.isRentalHouse) {
        window.alert('공공 임대 게시글은 작성이 불가능해요.');
        return;
      }
      const { addressOnly, addressWithBuilding } = parseAddress(node.address);
      const coordinate = await coordinateFinder.findByAddress(addressOnly);

      if (coordinate.length === 0) {
        window.alert('등록할 수 없는 주소에요.');
        return;
      }

      const item: CorFormState = {
        address: addressWithBuilding,
        fullAddress: node.address,
        coordinate: {
          lon: coordinate[0].substring(0, 12),
          lat: coordinate[1].substring(0, 12),
        },
        corRealtyId: node.corRealtyId,
      };

      handleSubmit(item);
    } catch (e: unknown) {
      /* empty */
    } finally {
      setIsChecking(false);
    }
  };

  return {
    isChecking,
    handleAddressItemClick: handleItemClick,
  };
};

export default useCheckWritableAddress;
