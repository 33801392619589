import { config } from '@/config';
import qs from 'query-string';

export const validateVideoFileType = (type: string) => {
  return /^video\/(3gpp|3gp2|3gpp2|mpeg|mp4|x-m4v|quicktime|webm)/.test(type);
};

export const getVideoDuration = (file: File) => {
  return new Promise<number | null>((resolve, reject) => {
    try {
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        resolve(video.duration);
      };

      video.onerror = function () {
        window.URL.revokeObjectURL(video.src);
        resolve(null);
      };

      video.src = window.URL.createObjectURL(file);
    } catch (e) {
      reject(e);
    }
  });
};

const STREAM_URL = `https://customer-${config.cloudflareCode}.cloudflarestream.com`;

// https://developers.cloudflare.com/stream/viewing-videos/displaying-thumbnails/
type CFVideoThumbnailOption = {
  width?: number;
  height?: number;
  time?: string;
  fit?: 'crop' | 'scale' | 'clip' | 'fill';
} & ({ type: 'jpg' } | { type: 'gif'; duration?: string; fps?: number });

export const getCFVideoUrl = (cfVideoId: string) => {
  return `${STREAM_URL}/${cfVideoId}/iframe`;
};

export const getCFVideoSourceUrl = (cfVideoId: string) =>
  `${STREAM_URL}/${cfVideoId}/manifest/video.m3u8`;

export const getCFVideoThumbnailUrl = (cfVideoId: string, option?: CFVideoThumbnailOption) => {
  const { type = 'jpg', ...otherOptions } = option || {};

  return `${STREAM_URL}/${cfVideoId}/thumbnails/thumbnail.${type}?${qs.stringify(otherOptions)}`;
};

export const trimVideoName = (name: string, maxLength = 20) => {
  if (!name) {
    return '';
  }

  if (name.length > maxLength) {
    const splitted = name.split('.');
    const extension = splitted.pop();
    const filename = splitted.join('.');

    const trimmedFileName = `${filename.slice(0, 8)}..${filename.slice(-2)}`;

    return `${trimmedFileName}.${extension}`;
  }
  return name;
};
