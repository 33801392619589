import type { BrokerFaqBannerQuery } from '@/__generated__/BrokerFaqBannerQuery.graphql';
import { config } from '@/config';
import logger from '@/utils/Logger';
import { ActionCountKey, ActionHistoryKey, realtyLocalStorage } from '@/utils/localStorage';
import { InViewChecker } from '@daangn/realty-react/components';
import {
  ActionableCallout,
  Callout,
  CalloutDescription,
  CalloutTitle,
} from '@daangn/sprout-components-callout';
import { IconLoudspeakerFill } from '@seed-design/react-icon';
import { useState } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';

const MIN_IMPRESSION_COUNT_FOR_HIDE = 3;

const BrokerFaqBanner = () => {
  const { currentBrokerNotice } = useLazyLoadQuery<BrokerFaqBannerQuery>(
    graphql`
      query BrokerFaqBannerQuery {
        currentBrokerNotice {
          originalId
          content
          targetUri
        }
      }
    `,
    {}
  );

  const [showBrokerFaqBanner] = useState(() => {
    const count = realtyLocalStorage.getActionCount(ActionCountKey.BrokerFaqBannerImpression);
    const hasClicked = realtyLocalStorage.getActionHistory(ActionHistoryKey.ClickedBrokerFaqBanner);

    return !hasClicked && count <= MIN_IMPRESSION_COUNT_FOR_HIDE;
  });

  const handleImpression = () => {
    realtyLocalStorage.addActionCount(ActionCountKey.BrokerFaqBannerImpression);

    logger.impression({
      name: 'show_broker_faq_banner',
    });
  };

  const handleClick = () => {
    realtyLocalStorage.setActionHistory(ActionHistoryKey.ClickedBrokerFaqBanner);

    window.open(config.brokerFaqUrl, '_blank');

    logger.click({
      name: 'click_broker_faq_banner',
    });
  };

  const handleNoticeImpression = () => {
    logger.impression({
      name: 'show_broker_notice',
    });
  };

  const handleNoticeClick = () => {
    if (currentBrokerNotice) {
      logger.click({
        name: 'click_broker_notice',
        ...currentBrokerNotice,
      });

      window.open(currentBrokerNotice.targetUri, '_blank');
    }
  };

  if (currentBrokerNotice) {
    return (
      <InViewChecker
        options={{ threshold: 0.5, once: true }}
        onInView={handleNoticeImpression}
        onClick={handleNoticeClick}
      >
        <Callout
          width="100%"
          variant="info"
          icon={<IconLoudspeakerFill size={16} />}
          UNSAFE_className="cursor-pointer"
        >
          <CalloutTitle>안내</CalloutTitle>
          <CalloutDescription>{currentBrokerNotice.content}</CalloutDescription>
        </Callout>
      </InViewChecker>
    );
  }

  if (showBrokerFaqBanner) {
    return (
      <InViewChecker options={{ threshold: 0.5, once: true }} onInView={handleImpression}>
        <ActionableCallout width="100%" onClick={handleClick}>
          <CalloutTitle>안내</CalloutTitle>
          <CalloutDescription>중개사용 FAQ 및 공지사항을 확인해보세요.</CalloutDescription>
        </ActionableCallout>
      </InViewChecker>
    );
  }

  return null;
};

export default BrokerFaqBanner;
